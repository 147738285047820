import React, { useState, type ChangeEvent } from 'react';
import emailjs from 'emailjs-com';

import Button from './Button';
import classNames from 'classnames';
import Input from './Input';

interface EmailFormProps {
  htmlContent?: string;
  showResults: boolean;
  setShowResults: (show: boolean) => void;
}

interface UserData {
  name: string;
  email: string;
  phone: string;
}

const EmailForm: React.FC<EmailFormProps> = ({
  htmlContent,
  showResults,
  setShowResults
}) => {
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [formError, setFormError] = useState<boolean>(false);

  const [userData, setUserData] = useState<UserData>({
    name: '',
    email: '',
    phone: ''
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value
    });

    setFormError(false);

    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailValid(emailRegex.test(value));
    }
  };

  const handleSubmit = (): void => {
    if (userData.name && userData.email && emailValid && userData.phone) {
      setShowResults(true);
      setFormError(false);
      console.log(htmlContent);
      sendEmail(htmlContent ?? '');
    } else {
      setFormError(true);
    }
  };

  const sendEmail = (htmlContent: string): void => {
    const templateParams = {
      from_name: 'Anderson Miranda Coaching',
      to_name: userData.name,
      to_email: userData.email,
      to_phone: userData.phone,
      message_html: htmlContent
    };
    const publicKey = 'jl8F2dQIYXoXzo-Dy';
    emailjs
      .send('service_sfpzqdm', 'template_8n2tod1', templateParams, publicKey)
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (err) => {
          console.log('FAILED...', err);
        }
      );
  };

  return (
    <section
      className={classNames(
        'mt-8 flex flex-col gap-4 w-full max-w-[370px]',
        showResults ? 'hidden' : 'block'
      )}
    >
      <p>Estamos preparando tus resultados y lo enviaremos a tu correo.</p>
      <p>Por favor introduzca tu datos:</p>
      <Input
        type="text"
        name="name"
        placeholder="Tu nombre"
        value={userData.name}
        onChange={handleInputChange}
      />
      <Input
        type="email"
        name="email"
        placeholder="Tu correo"
        value={userData.email}
        onChange={handleInputChange}
      />
      {!emailValid && <p className="text-sm text-primary">Correo inválido</p>}
      <Input
        type="text"
        name="phone"
        placeholder="Tu móvil"
        value={userData.phone}
        onChange={handleInputChange}
      />
      {formError && (
        <p className="text-sm text-primary">
          Por favor complete todos los campos
        </p>
      )}
      <Button
        onClick={() => {
          handleSubmit();
        }}
      >
        Quiero ver los resultados
      </Button>
    </section>
  );
};

export default EmailForm;
