import classNames from 'classnames';
import React, { type ReactNode, type FC } from 'react';

interface ButtonProps {
  children: ReactNode;
  className?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: VoidFunction;
}

const Button: FC<ButtonProps> = ({ children, onClick, type, className }) => {
  return (
    <button
      type={type}
      className={classNames(
        'border inline-flex items-center justify-center py-3 px-7 transition-all duration-300',
        'text-center text-sm font-medium text-white uppercase',
        'bg-primary border-primary',
        'hover:border-primary-alt hover:bg-white hover:text-primary-alt',
        'disabled:bg-gray-3 disabled:border-gray-3 disabled:text-dark-5',
        'active:text-primary',
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
