export interface Question {
  text: string;
  type: number;
}

export const labels = [
  'Nunca',
  'Casi nunca',
  'A veces',
  'Varias veces',
  'Casi siempre',
  'Siempre'
];

export const typeNames = [
  'El Perfeccionista',
  'El Apoyador',
  'El Triunfador',
  'El Individualista',
  'El Investigador',
  'El Leal',
  'El Entusiasta',
  'El Desafiador',
  'El Pacificador'
];

export const typeDescriptions = [
  'El tipo idealista de sólidos principios. Las personas tipo Uno son éticas y concienzudas, poseen un fuerte sentido del bien y el mal. Son profesores y cruzados, se esfuerzan siempre por mejorar las cosas, pero temen cometer errores. Bien organizados, ordenados y meticulosos, tratan de mantener valores elevados, pero pueden resultar críticos y perfeccionistas. Normalmente tienen problemas de rabia e impaciencia reprimidas. En su mejor aspecto, el Uno sano es sabio, perceptivo, realista y noble, a la vez que moralmente heroico.',
  'El tipo preocupado, orientado a los demás. Los Dos son comprensivos, sinceros y bondadosos; son amistosos, generosos y abnegados, pero también pueden ser sentimentales, aduladores y obsequiosos. Desean intimar con los demás y suelen hacer cosas por ellos para sentirse necesitados. Por lo general tienen problemas para cuidar de sí mismos y reconocer sus propias necesidades. En su mejor aspecto, el Dos sano es generoso, altruista y siente un amor incondicional por sí mismo y por los demás.',
  'El tipo adaptable y orientado al éxito. Las personas tipo Tres son seguras de sí mismas, atractivas y encantadoras. Ambiciosas, competentes y enérgicas, también pueden ser muy conscientes de su posición y estar muy motivadas por el progreso personal. Suelen preocuparse por su imagen y por lo que los demás piensan de ellas. Normalmente tienen problemas de adicción al trabajo y de competitividad. En su mejor aspecto, el Tres sano se acepta a sí mismo, es auténtico, es todo lo que aparenta ser, un modelo que inspira a otras personas.',
  'El tipo romántico e introspectivo. Los tipos Cuatro son conscientes de sí mismos, sensibles, reservados y callados. Son demostrativos, sinceros y personales emocionalmente, pero también pueden ser caprichosos y tímidos. Se ocultan de los demás porque se sienten vulnerables o defectuosos, pero también pueden sentirse desdeñosos y ajenos a las formas normales de vivir. Normalmente tienen problemas de autocomplacencia y autocompasión. En su mejor aspecto, los tipos Cuatro sanos son inspirados y muy creativos, capaces de renovarse y transformar sus experiencias.',
  'El tipo vehemente y cerebral. Los Cinco son despabilados, perspicaces y curiosos. Son capaces de concentrarse y enfocar la atención en desarrollar ideas y habilidades complejas. Independientes e innovadores, es posible que se obsesionen con sus pensamientos y elaboraciones imaginarias. Se desligan de las cosas, pero son muy nerviosos y vehementes. Por lo general tienen problemas de aislamiento, excentricidad y nihilismo. En su mejor aspecto, el Cinco sano es pionero visionario, suele estar en la vanguardia y es capaz de ver el mundo de un modo totalmente nuevo.',
  'El tipo comprometido, orientado a la seguridad. Las personas tipo Seis son dignas de confianza, trabajadoras y responsables, pero también pueden adoptar una actitud defensiva, ser evasivas y muy nerviosas; trabajan hasta estresarse al mismo tiempo que se quejan de ello. Suelen ser cautelosas e indecisas, aunque también reactivas, desafiantes y rebeldes. Normalmente tienen problemas de inseguridad y desconfianza. En su mejor aspecto, los Seis sanos son estables interiormente, seguros de sí mismos, independientes, y apoyan con valentía a los débiles e incapaces.',
  'El tipo productivo y ajetreado. Los Siete son versátiles, optimistas y espontáneos; juguetones, animosos y prácticos, también podrían abarcar demasiado, ser desorganizados e indisciplinados. Constantemente buscan experiencias nuevas y estimulantes, pero la actividad continuada los aturde y agota. Por lo general tienen problemas de superficialidad e impulsividad. En su mejor aspecto, los Siete sanos centran sus dotes en objetivos dignos, son alegres, muy capacitados y muy agradecidos.',
  'El tipo poderoso y dominante. Las personas tipo Ocho son seguras de sí mismas, fuertes y capaces de imponerse. Protectoras, ingeniosas y decididas, también resultan orgullosas y dominantes; piensan que deben estar al mando de su entorno y suelen volverse retadoras e intimidadoras. Normalmente tienen problemas para intimar con los demás. En su mejor aspecto, los Ocho sanos se controlan, usan su fuerza para mejorar la vida de otras personas, volviéndose heroicos, magnánimos y a veces históricamente grandiosos.',
  'El tipo acomodadizo, humilde. Los tipos Nueve son conformistas, confiados y estables. Son afables, bondadosos, se acomodan con facilidad y ofrecen su apoyo, pero también pueden estar demasiado dispuestos a transigir con los demás para mantener la paz. Desean que todo vaya sobre ruedas, sin conflictos, pero tienden a ser complacientes y a minimizar cualquier cosa inquietante. Normalmente tienen problemas de pasividad y tozudez. En su mejor aspecto, los Nueve sanos son indómitos y abarcadores; son capaces de unir a las personas y solucionar conflictos.'
];

export const questions = [
  {
    text: 'Por lo general me consideran una persona seria y sensata, y una vez dicho y hecho todo, supongo que lo soy.',
    type: 1
  },
  {
    text: 'Siempre he intentado ser sincero y objetivo respecto a mí mismo, y estoy decidido a ser fiel a mi conciencia, cueste lo que cueste.',
    type: 1
  },
  {
    text: 'Si bien una parte de mí podría ser alocada, en términos generales no ha sido precisamente ese mi estilo.',
    type: 1
  },
  {
    text: 'Tengo la impresión de que tengo un juez en la cabeza: a veces este juez es sabio y perspicaz, pero muchas otras es simplemente severo y duro.',
    type: 1
  },
  {
    text: 'Creo que he pagado un elevado precio por tratar de ser perfecto.',
    type: 1
  },
  {
    text: 'Me gusta reírme como a cualquiera; ¡debería hacerlo más a menudo!',
    type: 1
  },
  {
    text: 'Mis principios e ideales me estimulan hacia mayores logros y dan sentido y valor a mi vida.',
    type: 1
  },
  {
    text: 'No entiendo por qué tantas personas tienen valores morales tan laxos.',
    type: 1
  },
  {
    text: 'Depende tanto de mí que se hagan las cosas que tengo que ser más organizado y metódico que los demás.',
    type: 1
  },
  {
    text: 'Me imagino que tengo una misión personal, tal vez una vocación para algo superior, y creo que durante mi vida podría realizar algo extraordinario.',
    type: 1
  },
  {
    text: 'Detesto los errores, por lo tanto tiendo a ser muy minucioso para asegurarme de que las cosas se hagan bien.',
    type: 1
  },
  {
    text: 'Gran parte de mi vida he creído que lo que está bien, está bien, y que lo que está mal, está mal, y ya está.',
    type: 1
  },
  {
    text: 'Me cuesta muchísimo dejar que las cosas se hagan sólo suficientemente bien.',
    type: 1
  },
  {
    text: 'Han recaído muchas responsabilidades sobre mis hombros: si no hubiera estado a la altura de las circunstancias, sólo Dios sabe lo que habría ocurrido.',
    type: 1
  },
  {
    text: 'Me conmueve muchísimo la nobleza humana y que se conserve el buen talante en las dificultades.',
    type: 1
  },
  {
    text: 'Mí auténtico interés por los demás hace que me involucre profundamente con ellos, con sus esperanzas, sus sueños y necesidades.',
    type: 2
  },
  {
    text: 'Me resulta natural ser amistoso; entablo conversaciones con facilidad y me tuteo con todo el mundo.',
    type: 2
  },
  {
    text: 'He descubierto que las personas reaccionan con simpatía cuando les presto atención y les doy aliento.',
    type: 2
  },
  {
    text: 'No puedo ver un perro extraviado en la calle sin desear llevármelo a casa.',
    type: 2
  },
  {
    text: 'Me siento bien por ser una persona considerada y generosa.',
    type: 2
  },
  {
    text: 'Me cuesta creer las muchas cosas que he hecho por la gente, pero me molesta mucho que no den señales de darse cuenta o de que les importe.',
    type: 2
  },
  {
    text: 'Es cierto que suelo hacer más por los demás de lo que debiera; doy demasiado y no pienso en mí lo suficiente.',
    type: 2
  },
  {
    text: 'Con frecuencia me sorprendo intentando conquistar a las personas, sobre todo si al principio se muestran indiferentes a mi.',
    type: 2
  },
  {
    text: 'Me produce una alegría especial recibir en casa y atender a mis amigos y familiares.',
    type: 2
  },
  {
    text: 'Sé ser amable y sustentador, pero hay en mí más dureza de la que se podría suponer.',
    type: 2
  },
  {
    text: 'Soy capaz de expresar mis sentimientos por las personas con más franqueza que la mayoría.',
    type: 2
  },
  {
    text: 'Hago un esfuerzo especial por saber qué les pasa a las personas que quiero.',
    type: 2
  },
  {
    text: 'Me considero algo así como un «sanador de corazones rotos».',
    type: 2
  },
  {
    text: 'Con frecuencia mi salud y mi economía han sufrido debido a que antepongo las necesidades e intereses de los demás a los míos.',
    type: 2
  },
  {
    text: 'Me encanta desvivirme para que las personas se sientan acogidas y apreciadas.',
    type: 2
  },
  {
    text: 'Me considero una persona muy competente: en realidad, me molesta no ser eficaz y eficiente.',
    type: 3
  },
  {
    text: 'Cuando las cosas me van bien, prácticamente «brillo» con una especie de alegría interior por ser quien soy y tener la vida que tengo.',
    type: 3
  },
  {
    text: 'Trato de presentarme ante los demás bajo la mejor luz posible, pero eso es lo que hace todo el mundo, ¿verdad?',
    type: 3
  },
  {
    text: 'Mis sentimientos tienden a serme ajenos; siento las cosas con mucha intensidad por un instante y, a continuación, simplemente sigo con lo mío.',
    type: 3
  },
  {
    text: 'Es importante para mí sentir que tengo éxito, aunque aún no sea el éxito que deseo.',
    type: 3
  },
  {
    text: 'Para bien o para mal, soy bueno para encubrir mis inseguridades; nadie adivina jamás qué siento realmente.',
    type: 3
  },
  {
    text: 'Deseo causar buena impresión, de modo que normalmente soy cortés, educado y amigable.',
    type: 3
  },
  {
    text: 'Sé lo bien que les va a mis amigos y colegas, y tiendo a compararme con ellos.',
    type: 3
  },
  {
    text: 'Suelo esforzarme por ser el mejor en lo que hago; sí no puedo sobresalir en algo, por lo general no me molesto en hacerlo.',
    type: 3
  },
  {
    text: 'A veces he tenido que tomar unos pocos atajos para conseguir mis objetivos.',
    type: 3
  },
  {
    text: 'Cuando me siento inseguro puedo ser algo reservado y frío con las personas.',
    type: 3
  },
  {
    text: 'Claro que me molesta que los demás no reconozcan la excelencia en lo que hago.',
    type: 3
  },
  {
    text: 'Soy más adaptable que la mayoría: si las cosas no van bien, sé cambiar mi comportamiento para obtener los resultados que deseo.',
    type: 3
  },
  {
    text: 'Siempre tengo un objetivo en mente, y sé motivarme para conseguirlo,',
    type: 3
  },
  {
    text: 'Tengo una vena de adicción al trabajo; me siento perdido si no estoy realizando cosas.',
    type: 3
  },
  {
    text: 'Muchos me consideran una persona enigmática, difícil y contradictoria, ¡y eso me gusta de mí!',
    type: 4
  },
  {
    text: 'Tiendo a obsesionarme con mis sentimientos negativos un buen tiempo antes de liberarme de ellos.',
    type: 4
  },
  {
    text: 'Suelo sentirme solo, incluso cuando estoy con personas amigas.',
    type: 4
  },
  {
    text: 'Si me critican o entienden mal, tiendo a cerrarme en mí mismo y a ponerme de mal humor.',
    type: 4
  },
  {
    text: 'Me cuesta participar en proyectos si no tengo el control creativo.',
    type: 4
  },
  {
    text: 'Tiendo a no seguir las reglas ni las expectativas porque quiero poner mi sello especial en todo lo que hago.',
    type: 4
  },
  {
    text: 'Según muchos criterios, soy bastante dramático y temperamental.',
    type: 4
  },
  {
    text: 'Tiendo a pasar bastante tiempo imaginando situaciones y conversaciones que no han ocurrido necesariamente.',
    type: 4
  },
  {
    text: 'Ansío que alguien me rescate y me arranque de toda esta monotonía.',
    type: 4
  },
  {
    text: 'Cuando las cosas se ponen difíciles, tiendo a desmoronarme y renunciar; tal vez tiro la toalla con demasiada facilidad.',
    type: 4
  },
  {
    text: 'Soy capaz de perdonarme casi cualquier cosa, menos el mal gusto.',
    type: 4
  },
  {
    text: 'Por lo general, no me gusta trabajar demasiado cerca de los demás.',
    type: 4
  },
  {
    text: 'Conocerme a mí mismo y ser fiel a mis necesidades emocionales han sido motivaciones muy importantes para mí.',
    type: 4
  },
  { text: 'No me gusta tomar el mando ni obedecer órdenes.', type: 4 },
  {
    text: 'Soy muy consciente de mis intuiciones, tenga o no el valor para seguirlas.',
    type: 4
  },
  {
    text: 'Me gusta profundizar en las cosas, y estudio detenidamente los detalles hasta que lo he entendido todo lo mejor posible.',
    type: 5
  },
  {
    text: 'Soy una persona muy reservada, no dejo entrar a muchas personas en mi mundo.',
    type: 5
  },
  {
    text: 'No me siento muy importante ni poderoso, más bien pequeño e invisible: ¡Sería un buen espía!',
    type: 5
  },
  {
    text: 'Si los demás supieran las cosas que pienso la mayor parte del tiempo creerían que estoy loco.',
    type: 5
  },
  {
    text: 'Sólo teniendo la información correcta se puede tomar una decisión racional, pero, claro, la mayoría de la gente no es muy racional.',
    type: 5
  },
  {
    text: 'Mi familia me encuentra algo raro o excéntrico; ciertamente me han dicho que debo salir más.',
    type: 5
  },
  {
    text: 'Hablo bastante cuando quiero, pero la mayor parte del tiempo prefiero limitarme a observar la locura que me rodea.',
    type: 5
  },
  {
    text: 'Si hay que resolver un problema, que me dejen trabajarlo solo y saldré con la respuesta.',
    type: 5
  },
  {
    text: 'Pensándolo bien, no se puede ser más raro que aquellos que se comportan de manera normal.',
    type: 5
  },
  {
    text: 'Tiendo a tardar muchísimo en perfeccionar los proyectos en que trabajo.',
    type: 5
  },
  {
    text: 'La mayoría de las personas son increíblemente ignorantes; es asombroso que algo funcione.',
    type: 5
  },
  {
    text: 'Sé mucho sobre muchas cosas, y en algunos campos me considero un experto.',
    type: 5
  },
  {
    text: 'Soy muy curioso y me gusta investigar por qué las cosas son como son; incluso las cosas obvias no son tan obvias si se miran bien.',
    type: 5
  },
  {
    text: 'Mi mente es tan vehemente y activa que a veces me parece que está ardiendo.',
    type: 5
  },
  {
    text: 'Me concentro tanto en lo que hago que suelo no enterarme del paso del tiempo.',
    type: 5
  },
  {
    text: 'Me atrae la autoridad, pero al mismo tiempo desconfío de ella.',
    type: 6
  },
  {
    text: 'Soy muy emotivo, pero no suelo demostrar mis sentimientos, a no ser a mis íntimos, e incluso a ellos, no siempre.',
    type: 6
  },
  {
    text: 'Si cometo un error temo que todos me van a criticar de inmediato.',
    type: 6
  },
  {
    text: 'Me siento más seguro haciendo lo que se espera de mí que obrando por mi cuenta.',
    type: 6
  },
  {
    text: 'Es posible que no siempre esté de acuerdo con las reglas, y que no siempre las siga, pero deseo conocerlas.',
    type: 6
  },
  {
    text: 'Tiendo a tener primeras impresiones fuertes sobre las personas, y me cuesta cambiarlas.',
    type: 6
  },
  {
    text: 'Hay unas cuantas personas a las que admiro de veras, son algo así como héroes para mí.',
    type: 6
  },
  {
    text: 'No me gusta tomar grandes decisiones, pero ciertamente no deseo que otra persona las tome por mí.',
    type: 6
  },
  {
    text: 'Algunas personas me consideran muy inquieto y nervioso, ¡pero no saben ni la mitad!',
    type: 6
  },
  {
    text: 'Sé cuánto enredo armo yo, de modo que encuentro lógico desconfiar de lo que pretenden los demás.',
    type: 6
  },
  {
    text: 'Deseo fiarme de las personas, pero suelo sorprenderme desconfiando de sus motivos.',
    type: 6
  },
  {
    text: 'Soy muy trabajador, de verdad: no paro hasta terminar un trabajo.',
    type: 6
  },
  {
    text: 'Cuando tengo que tomar una decisión importante, consulto la opinión de las personas en que confío.',
    type: 6
  },
  {
    text: 'Es muy extraño: puedo ser escéptico, incluso cínico, acerca de todo tipo de cosas, y de pronto me dejo engañar totalmente en algo.',
    type: 6
  },
  { text: 'Ansiedad podría ser mi segundo apellido.', type: 6 },
  {
    text: 'Me encanta viajar y descubrir diferentes tipos de comidas, personas y experiencias, ¡todo el fabuloso torbellino de la vida!',
    type: 7
  },
  {
    text: 'Mi agenda suele estar llena, y me gusta así: la hierba no crecerá bajo mis pies.',
    type: 7
  },
  {
    text: 'Lo importante para mi es la emoción y la variedad, no la comodidad ni la precaución, aunque aprovecho tas comodidades siempre que las encuentro.',
    type: 7
  },
  {
    text: 'Mi mente no para de parlotear, a veces me parece que estoy pensando en diez cosas al mismo tiempo.',
    type: 7
  },
  {
    text: 'Una cosa que no soporto de ninguna manera es el aburrimiento, y procuro no aburrirme nunca.',
    type: 7
  },
  {
    text: 'Me comprometo bastante cuando estoy en una relación, pero cuando se acaba, paso a otra cosa.',
    type: 7
  },
  {
    text: 'Soy curioso y arriesgado; por lo general, soy el primero entre mis amigos en probar cualquier cosa nueva e interesante.',
    type: 7
  },
  { text: 'Cuando ya no disfruto en una actividad dejo de hacerla.', type: 7 },
  {
    text: 'No soy sólo una «persona divertida», también tengo mi lado serio, incluso oscuro, aunque no me gusta mucho ir allí,',
    type: 7
  },
  {
    text: 'Soy bueno para lo general, no tanto para los detalles; disfruto más teniendo muchas ideas que en ocuparme de ponerlas en práctica.',
    type: 7
  },
  {
    text: 'Cuando de veras deseo algo, normalmente encuentro la manera de obtenerlo,',
    type: 7
  },
  {
    text: 'Las cosas me abaten de vez en cuando, pero muy pronto levanto cabeza.',
    type: 7
  },
  {
    text: 'Uno de mis principales problemas es que me distraigo con facilidad y me disperso demasiado.',
    type: 7
  },
  {
    text: 'Tiendo a gastar más dinero del que probablemente debería.',
    type: 7
  },
  {
    text: 'Me encanta estar con gente, siempre que quieran ir donde yo voy.',
    type: 7
  },
  {
    text: 'Soy extraordinariamente independiente y no me gusta depender de nadie para lo que necesito.',
    type: 8
  },
  {
    text: 'Creo que hay que «romper algunos huevos para hacer una tortilla».',
    type: 8
  },
  {
    text: 'Cuando quiero a las personas las considero «mi gente» y siento la necesidad de cuidar de sus intereses.',
    type: 8
  },
  {
    text: 'Sé cómo obtener resultados, sé cómo recompensar y apremiar a las personas para que hagan las cosas.',
    type: 8
  },
  {
    text: 'No siento mucha compasión por los débiles y los indecisos: la debilidad sólo atrae problemas.',
    type: 8
  },
  {
    text: 'Tengo una voluntad fuerte y no renuncio ni me echo atrás fácilmente.',
    type: 8
  },
  {
    text: 'No hay nada que me haga sentir más orgulloso que ver actuar por su cuenta a alguien a quien he tomado bajo mi protección.',
    type: 8
  },
  {
    text: 'Tengo un lado tierno, incluso algo sentimental, que muestro a muy pocas personas.',
    type: 8
  },
  {
    text: 'Las personas que me conocen me agradecen que les hable francamente y les diga exactamente lo que pienso.',
    type: 8
  },
  {
    text: 'He tenido que trabajar mucho por todo lo que tengo; creo que las dificultades son buenas porque te endurecen y te hacen tener claro lo que deseas.',
    type: 8
  },
  {
    text: 'Me considero un desafiador, una persona que saca a las personas de su zona de comodidad para que hagan todo lo que son capaces de hacer.',
    type: 8
  },
  {
    text: 'Mi sentido del humor es prosaico, incluso grosero a veces, aunque creo que la mayoría de las personas son demasiado remilgadas y sensibles.',
    type: 8
  },
  {
    text: 'Soy capaz de violentos estallidos de cólera, pero se pasan.',
    type: 8
  },
  {
    text: 'Me siento más vivo cuando hago lo que los demás creen imposible. Me gusta arriesgarme y comprobar si soy capaz de vencer los peligros.',
    type: 8
  },
  {
    text: 'Normalmente a alguien tiene que tocarle la situación desventajosa, y no quiero ser yo.',
    type: 8
  },
  {
    text: 'Parece que lo que gusta de mí a las personas es que se sienten seguras a mi lado.',
    type: 9
  },
  {
    text: 'No me importa estar con gente ni me importa estar solo; cualquiera de las dos cosas me va bien, siempre que esté en paz conmigo mismo.',
    type: 9
  },
  {
    text: 'He encontrado un cierto equilibrio en mí vida, y no veo ningún motivo para estropearlo.',
    type: 9
  },
  {
    text: 'Estar «cómodo» en todo el sentido de la palabra me atrae muchísimo.',
    type: 9
  },
  { text: 'Prefiero ceder antes que armar una escena.', type: 9 },
  {
    text: 'No sé exactamente cómo lo hago, pero no me dejo deprimir por las cosas.',
    type: 9
  },
  {
    text: 'Soy bastante fácil de complacer y normalmente creo que lo que tengo es suficiente para mí.',
    type: 9
  },
  {
    text: 'Me han dicho que parezco distraído; la verdad es que comprendo las cosas, pero no quiero reaccionar a ellas.',
    type: 9
  },
  {
    text: 'No me considero tozudo, pero me han dicho que lo soy bastante cuando he tomado una decisión.',
    type: 9
  },
  {
    text: 'Muchas personas se ponen frenéticas con demasiada facilidad; yo soy mucho más ecuánime.',
    type: 9
  },
  {
    text: 'Hay que aceptar lo que trae la vida, ya que de todos modos no es mucho lo que se puede hacer al respecto.',
    type: 9
  },
  {
    text: 'Me es fácil comprender diferentes puntos de vista y tiendo más a estar de acuerdo que en desacuerdo con las personas.',
    type: 9
  },
  {
    text: 'Prefiero acentuar lo positivo antes que insistir en lo negativo.',
    type: 9
  },
  {
    text: 'Tengo lo que se podría llamar una filosofía de la vida que me orienta y me da muchísimo consuelo en las épocas difíciles.',
    type: 9
  },
  {
    text: 'Durante el día hago todo lo que hay que hacer, pero cuando se acaba el día sé relajarme y tomarme las cosas con calma.',
    type: 9
  }
];

export const quickGroups = [
  {
    id: '1',
    options: [
      {
        text: 'Tiendo a ser bastante independiente y confiado: pienso que la vida va mejor cuando la esperas de frente. Me fijo objetivos, me comprometo y deseo que ocurran las cosas. No me gusta quedarme sentado, prefiero realizar algo grande y dejar mi huella. No busco necesariamente confrontaciones, pero no me dejo llevar ni empujar tampoco. La mayor parte del tiempo sé lo que quiero y voy a por ello. Tiendo a trabajar mucho y a disfrutar mucho.',
        value: 'A'
      },
      {
        text: 'Tiendo a estar callado, y estoy acostumbrado a estar solo. Normalmente no atraigo mucho la atención en el aspecto social, y por lo general procuro no imponerme por la fuerza. No me siento cómodo destacando sobre los demás ni siendo competitivo. Probablemente muchos dirían que tengo algo de soñador, pues disfruto con mi imaginación. Puedo estar bástame a gusto sin pensar que tengo que ser activo todo el tiempo.',
        value: 'B'
      },
      {
        text: 'Tiendo a ser muy responsable y entregado. Me siento fatal si no cumplo mis compromisos o no hago lo que se espera de mí. Deseo que los demás sepan que estoy por ellos y que haré todo lo que crea que es mejor por ellos. Con frecuencia hago grandes sacrificios personales por el bien de otros, lo sepan o no lo sepan. No suelo cuidar bien de mí mismo; hago el trabajo que hay que hacer y me relajo (y hago lo que realmente deseo) si me queda tiempo.',
        value: 'C'
      }
    ]
  },
  {
    id: '2',
    options: [
      {
        text: 'Soy una persona que normalmente mantiene una actitud positiva y piensa que las cosas se van a resolver para mejor. Sudo entusiasmarme por las cosas y no me cuesta encontrar en qué ocuparme. Me gusta estar con gente y ayudar a otros a ser felices; me agrada compartir con ellos mi bienestar. (No siempre me siento fabulosamente bien, pero trato de que nadie se dé cuenta.) Sin embargo, mantener esta actitud positiva ha significado a veces dejar pasar demasiado tiempo sin ocuparme de mis problemas.',
        value: 'X'
      },
      {
        text: 'Soy una persona que tiene fuertes sentimientos respecto a las cosas, la mayoría de la gente lo nota cuando me siento desgraciado por algo. Sé ser reservado con los demás, pero soy más sensible de lo que dejo ver. Deseo saber a qué atenerme con los demás y con quiénes y con qué puedo contar; la mayoría de las personas tienen muy claro a qué atenerse conmigo. Cuando estoy alterado por algo deseo que los demás reaccionen y se emocionen tanto como yo. Conozco las reglas, pero no quiero que me digan lo que he de hacer. Quiero decidir por mí mismo.',
        value: 'Y'
      },
      {
        text: 'Tiendo a controlarme y a ser lógico, me desagrada hacer frente a los sentimientos. Soy eficiente, incluso perfeccionista, y prefiero trabajar solo. Cuando hay problemas o conflictos personales trato de no meter mis sentimientos por medio. Algunos dicen que soy demasiado frío y objetivo, pero no quiero que mis reacciones emocionales me distraigan de lo que realmente me importa. Por lo general, no muestro mis emociones cuando otras personas «me fastidian».',
        value: 'Z'
      }
    ]
  }
];

export const quickResults: Record<string, number> = {
  AX: 7,
  AY: 8,
  AZ: 3,
  BX: 9,
  BY: 4,
  BZ: 5,
  CX: 2,
  CY: 6,
  CZ: 1
};
