import React, { type FC } from 'react';

const Header: FC = () => {
  return (
    <div className="bg-secondary h-16 md:h-20 text-white flex items-center px-6 sm:px-10 justify-between">
      <a target="blank" href="https://andersonmiranda.com">
        <img
          src="http://www.andersonmiranda.com/wp-content/uploads/2024/06/Logo-Horizontal-Color-Blanco.png"
          className="h-[34px] sm:h-[44px]"
        />
      </a>
      <div>
        <img src="./eneagrama.svg" className="w-10" />
      </div>
    </div>
  );
};

export default Header;
