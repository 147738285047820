import React, { useEffect, useState } from 'react';
import QuestionGroup from '../components/QuestionGroup';
import { quickGroups } from '../utils/constants';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

const QuickForm: React.FC = () => {
  const navigate = useNavigate();

  const [answers, setAnswers] = useState({ group1: '', group2: '' });
  const [formHasError, setFormHasError] = useState(false);
  const handleSelect = (group: string, value: string): void => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [`group${group}`]: value }));
  };

  const allAnswersFilled = (): boolean => {
    return answers.group1 !== '' && answers.group2 !== '';
  };

  const handleSubmit = (): void => {
    if (allAnswersFilled()) {
      const resultCode = answers.group1 + answers.group2;
      navigate('/quick-results', { state: { resultCode } });
      setFormHasError(false);
    } else {
      setFormHasError(true);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);

  return (
    <section>
      <h2 className="mb-6 font-title text-3xl font-semibold">
        Test Rápido de Eneagrama
      </h2>
      <p className="my-6 font-light text-gray2 sm:text-sm">
        En cada uno de los dos grupos siguientes, elige el párrafo que refleje
        mejor tus actitudes y tu comportamiento generales, según tu forma de ser
        la mayor parte de tu vida.
      </p>

      <p className="my-6 font-light text-gray2 sm:text-sm">
        No es necesario que estés totalmente de acuerdo con cada palabra o
        afirmación del párrafo que elijas. Basta con que estés de acuerdo en un
        80 o 90 por ciento con lo que dice el párrafo para elegirlo antes que
        los otros dos del grupo. Pero sí deberás identificarte con el tono
        general, con la ideología o «filosofía» global de ese párrafo.
      </p>

      <p className="my-6 font-light text-gray2 sm:text-sm">
        No analices demasiado para elegir. Elige el párrafo que tu «instinto» te
        dice que es el que te corresponde, aunque no estés de acuerdo con todo.
        Guíate por tu intuición.
      </p>
      {quickGroups.map((group) => (
        <QuestionGroup key={group.id} group={group} onSelect={handleSelect} />
      ))}

      {formHasError && (
        <p className="mb-4 text-primary-alt">
          Por favor elija una respuesta de cada Grupo antes de seguir
        </p>
      )}
      <Button onClick={handleSubmit}>Ver Resultado</Button>
    </section>
  );
};

export default QuickForm;
