import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shuffle } from '../utils/utils';

import { labels, questions as originalsQuestions } from '../utils/constants';
import Button from '../components/Button';
import ProgressBar from '../components/ProgressBar';

const questions = shuffle(originalsQuestions);

const TestForm = () => {
  const [answers, setAnswers] = useState(Array(questions.length).fill(null));

  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [formHasError, setFormHasError] = useState(false);
  const navigate = useNavigate();

  const allAnswersFilled = (page) => {
    const start = page * pageSize;
    const end = start + pageSize;
    return answers.slice(start, end).every((answer) => answer !== null);
  };

  const handleNext = () => {
    if (allAnswersFilled(currentPage)) {
      if (currentPage < Math.floor(questions.length / pageSize)) {
        setCurrentPage(currentPage + 1);
      }
      setFormHasError(false);
    } else {
      setFormHasError(true);
    }
  };

  const handleChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = parseInt(value, 10);
    setAnswers(updatedAnswers);
    setFormHasError(false);
  };

  const handleSubmit = (e) => {
    if (allAnswersFilled(currentPage)) {
      e.preventDefault();
      const scores = new Array(9).fill(0);

      answers.forEach((answer, index) => {
        scores[questions[index].type - 1] += Math.floor(
          answer * (180 / questions.length)
        );
      });
      navigate('/results', { state: { scores } });
      // console.log('Scores:', scores);
      setFormHasError(false);
    } else {
      setFormHasError(true);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [currentPage]);

  const currentQuestions = questions.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  return (
    <section id="form">
      <h2 className="mb-6 font-title text-3xl font-semibold">
        Test de Eneagrama
      </h2>

      <p className="text-sm font-light text-gray2">
        Página {currentPage + 1} de {Math.ceil(questions.length / pageSize)}
      </p>
      <ProgressBar
        current={currentPage + 1}
        total={Math.ceil(questions.length / pageSize)}
      />

      <div className="mt-8">
        <form onSubmit={handleSubmit}>
          {currentQuestions.map((question, index) => (
            <div key={index} className="mt-4">
              <label>
                {currentPage * pageSize + index + 1}. {question.text}
              </label>
              <div className="mb-6 mt-2">
                {[0, 1, 2, 3, 4, 5].map((score) => (
                  <p
                    key={score}
                    className="mt-1 font-light text-gray2 sm:text-sm"
                  >
                    <label className="flex items-center py-[2px]">
                      <input
                        type="radio"
                        name={`question-${currentPage * pageSize + index}`}
                        value={score}
                        checked={
                          answers[currentPage * pageSize + index] === score
                        }
                        onChange={(e) =>
                          handleChange(
                            currentPage * pageSize + index,
                            e.target.value
                          )
                        }
                        className="mr-2 size-6 sm:size-5"
                      />
                      {labels[score]}
                    </label>
                  </p>
                ))}
              </div>
            </div>
          ))}

          <div className="mt-6">
            {formHasError && (
              <p className="mb-4 text-primary-alt">
                Por favor conteste todas las preguntas antes de seguir
              </p>
            )}
            {currentPage !== Math.floor((questions.length - 1) / pageSize) && (
              <Button
                type="button"
                onClick={handleNext}
                disabled={
                  currentPage >= Math.floor(questions.length / pageSize)
                }
              >
                Siguiente
              </Button>
            )}
            {currentPage === Math.floor((questions.length - 1) / pageSize) && (
              <Button type="button" onClick={handleSubmit}>
                Obtener resultados
              </Button>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default TestForm;
