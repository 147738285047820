import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ProgressBar from '../components/ProgressBar';
import { typeDescriptions, typeNames } from '../utils/constants';
import Button from '../components/Button';
import classNames from 'classnames';
import { sortScores } from '../utils/utils';
import EmailForm from '../components/EmailForm';

const ResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resultsRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');

  const { scores } = location.state || { scores: [] }; //

  const sortedScores = sortScores(scores);

  useEffect(() => {
    if (scores.length === 0) {
      navigate('/');
    }
  }, [scores, navigate]);

  useEffect(() => {
    setHtmlContent(resultsRef.current?.innerHTML ?? '');
  }, []);

  return (
    <>
      <h2 className="font-title text-3xl font-semibold">
        Test de Eneagrama - Resultados
      </h2>

      <EmailForm
        htmlContent={htmlContent}
        showResults={showResults}
        setShowResults={setShowResults}
      />

      <section className={classNames(showResults ? 'block' : 'hidden')}>
        <div ref={resultsRef}>
          <ul>
            {sortedScores.map((item, index) => (
              <li key={index} className="mb-8 mt-4">
                <p className="text-sm text-gray2">Eneatipo {item.type + 1}</p>
                <strong className="mb-2 flex justify-between text-xl font-bold text-gray1">
                  {typeNames[item.type]}{' '}
                  <span className="ml-4 font-normal text-gray2 ">
                    {item.score} / 100
                  </span>
                </strong>
                <ProgressBar current={item.score} total={100} />
                {index === 0 && (
                  <>
                    <p className="mb-6 mt-2 font-light text-gray2">
                      {typeDescriptions[sortedScores[0].type]}
                    </p>
                    <a
                      target="_blank"
                      href={`https://andersonmiranda.com/eneatipo-${
                        item.type + 1
                      }`}
                      rel="noreferrer"
                    >
                      <Button className="mt-6">
                        Saber más sobre el Eneatipo {item.type + 1}
                      </Button>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
        <Button onClick={() => navigate('/')}>Hacer nuevo test</Button>
      </section>
    </>
  );
};

export default ResultsPage;
