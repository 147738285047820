import classNames from 'classnames';
import React, { type FC } from 'react';

interface ProgressBarProps {
  current: number;
  total: number;
  className: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ current, total, className }) => {
  const percentage = Math.round((current / total) * 100);

  return (
    <div className="h-4 w-full rounded bg-gray-200">
      <div
        className={classNames(
          'h-full bg-primary rounded transition-width duration-500',
          className
        )}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
