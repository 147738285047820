import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button';

const ResultsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <h2 className="font-title text-3xl font-semibold">Test de Eneagrama</h2>
      <section className="mt-8">
        <img src="/eneagrama-red.svg" className="w-[250px]" />
        <p className="my-6 font-title text-xl font-semibold">
          Descubre tu verdadero potencial.
        </p>
        <p className="my-6">
          El Eneagrama es un antiguo sistema que clasifica la personalidad en
          nueve tipos distintos, cada uno con sus subtipos, ofreciendo una
          profunda comprensión de las motivaciones, miedos y comportamientos
          humanos.
        </p>
        <p className="my-6">
          Conocer estos tipos y sus variaciones proporciona valiosas
          perspectivas para el desarrollo personal y las relaciones
          interpersonales.
        </p>
        <p className="my-6">
          Aunque ningún cuestionario puede determinar con exactitud tu Eneatipo,
          el test de Riso & Hudson te guía hacia la autocomprensión, facilitando
          el descubrimiento de los rasgos que definen tu personalidad.
        </p>
        <p className="my-6">
          Para una exploración más detallada, ofrecemos una consulta
          personalizada, donde podrás descubrir tu instinto dominante y tipo de
          Eneatipo con precisión experta.
        </p>
        <p className="my-6 font-title text-xl font-semibold">
          ¡Inicia este viaje transformador hacia el autoconocimiento y el
          crecimiento personal con nosotros!
        </p>
        <div className="flex flex-col gap-4 text-sm font-light text-gray2 sm:flex-row">
          <div>
            <Button onClick={() => navigate('/quick-test')} className="mt-6">
              Empezar el test rápido
            </Button>
            <p className="mt-3 pr-4">
              El test rápido TRIE Riso-Hudson consta de solo{' '}
              <strong>6 preguntas</strong> y tiene una precisión aproximada del{' '}
              <strong>60-70%</strong>. Es perfecto si buscas una introducción
              rápida a tu Eneatipo.
            </p>
          </div>
          <div>
            <Button onClick={() => navigate('/test')} className="mt-6">
              Empezar el test completo
            </Button>
            <p className="mt-3">
              El test ITA Riso & Hudson consta de <strong>135 preguntas</strong>{' '}
              y tiene una precisión aproximada del <strong>80%</strong>. Este
              test detallado te proporcionará un perfil mas completo y profundo
              de tu personalidad.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResultsPage;
