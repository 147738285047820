import React, { type FC } from 'react';

const Footer: FC = () => {
  return (
    <div className="flex h-16 w-full items-center justify-between bg-secondary px-6 text-sm text-white sm:px-10">
      <div>
        © 2024,{' '}
        <a target="blank" href="https://andersonmiranda.com">
          Anderson Miranda - Coaching y Liderazgo.
        </a>
      </div>
      <a
        target="blank"
        href="https://instagram.com/andersonmirandacoach"
        className="shrink-0"
      >
        <img src="./instagram_logo.svg" className="size-6" />
      </a>
    </div>
  );
};

export default Footer;
