import React, { type FC } from 'react';

interface Option {
  text: string;
  value: string;
}

interface QuestionGroupProps {
  group: {
    id: string;
    options: Option[];
  };
  onSelect: (groupId: string, value: string) => void;
}

const QuestionGroup: FC<QuestionGroupProps> = ({ group, onSelect }) => {
  return (
    <div className="mb-6 ">
      <h2 className="mb-4 font-title text-xl font-semibold">
        Grupo {group.id}
      </h2>
      {group.options.map((option, index) => (
        <div key={index} className="mb-4">
          <label className="flex items-start py-[2px]">
            <input
              type="radio"
              name={`group-${group.id}`}
              value={option.value}
              onChange={() => {
                onSelect(group.id, option.value);
              }}
              className="mr-4 mt-1 size-6 shrink-0 sm:size-5"
            />
            {option.text}
          </label>
        </div>
      ))}
    </div>
  );
};

export default QuestionGroup;
