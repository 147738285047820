import React, { type FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Form from './pages/Form';
import Results from './pages/Results';
import QuickForm from './pages/QuickForm';
import QuickResults from './pages/QuickResults';

const App: FC = () => {
  return (
    <>
      <Header />
      <div className="min-h-[calc(100vh-144px)] p-10 text-gray1 md:mx-10 lg:mx-20">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/test" element={<Form />} />
            <Route path="/results" element={<Results />} />
            <Route path="/quick-test" element={<QuickForm />} />
            <Route path="/quick-results" element={<QuickResults />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </>
  );
};

export default App;
