import React, { type ChangeEvent, type FC } from 'react';
import classNames from 'classnames';

interface InputProps {
  name: string;
  value: string | number;
  placeholder: string;
  className?: string;
  type: 'text' | 'number' | 'email' | 'password';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({
  name,
  value,
  onChange,
  type,
  className,
  placeholder
}) => {
  return (
    <input
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      className={classNames(
        ' border-gray-200 border rounded-lg items-center py-3 px-7 font-medium  disabled:bg-gray-100 disabled:text-gray-300',
        className
      )}
      onChange={onChange}
    />
  );
};

export default Input;
