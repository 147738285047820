export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const selectRandomElementsByType = (questions, n) => {
  const groupedByType = questions.reduce((acc, question) => {
    if (!acc[question.type]) {
      acc[question.type] = [];
    }
    acc[question.type].push(question);
    return acc;
  }, {});

  const selectedQuestions = [];
  Object.keys(groupedByType).forEach((type) => {
    const questionsOfType = groupedByType[type];
    const shuffled = questionsOfType.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, n);
    selectedQuestions.push(...selected);
  });

  return selectedQuestions;
};

export const sortScores = (scores) => {
  return scores
    .map((score, index) => ({ score, type: index }))
    .sort((a, b) => b.score - a.score);
};
