import React, { type FC, useEffect, useRef, useState } from 'react';
import { quickResults, typeDescriptions, typeNames } from '../utils/constants';
import Button from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import EmailForm from '../components/EmailForm';

const QuickResults: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resultsRef = useRef<HTMLDivElement>(null);
  const [showResults, setShowResults] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');

  const { resultCode } = location.state || { resultCode: null }; //

  const enneatype = quickResults[resultCode];

  useEffect(() => {
    if (!resultCode) {
      navigate('/');
    }
  }, [resultCode, navigate]);

  useEffect(() => {
    setHtmlContent(resultsRef.current?.innerHTML ?? '');
  }, []);

  return (
    <>
      <h2 className="font-title text-3xl font-semibold">
        Test de Eneagrama - Resultado
      </h2>

      <EmailForm
        htmlContent={htmlContent}
        showResults={showResults}
        setShowResults={setShowResults}
      />

      <section className={classNames(showResults ? 'block' : 'hidden')}>
        <div ref={resultsRef}>
          <p className="mt-6 text-sm text-gray2">Eneatipo {enneatype}</p>
          <p className="mb-4  text-xl font-bold text-gray1">
            {typeNames[enneatype - 1]}
          </p>
          <p className="my-2 font-light text-gray2">
            {typeDescriptions[enneatype - 1]}
          </p>
          <a
            target="_blank"
            href={`https://andersonmiranda.com/eneatipo-${enneatype}`}
            rel="noreferrer"
          >
            <Button className="my-4">
              Saber más sobre el Eneatipo {enneatype}
            </Button>
          </a>
        </div>
        <p className="mb-6 mt-10 font-title text-xl font-semibold">
          ¿Quieres conocerte un poco más?
        </p>

        <Button
          onClick={() => {
            navigate('/test');
          }}
          className="mt-2"
        >
          Empezar el test completo
        </Button>
        <p className="mt-3 text-sm font-light text-gray2">
          El test ITA Riso & Hudson consta de <strong>135 preguntas</strong> y
          tiene una precisión aproximada del <strong>80%</strong>. Este test
          detallado te proporcionará un perfil mas completo y profundo de tu
          personalidad.
        </p>
      </section>
    </>
  );
};

export default QuickResults;
